<script>
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  BookIcon,
  MessageCircleIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import emailjs from "emailjs-com";
import mailbox from "../Components/mailbox";

/**
 * Page-contact-one component
 */
export default {
  data() {
    return {};
  },
  components: {
    Navbar,
    Footer,
    UserIcon,
    ArrowUpIcon,
    MailIcon,
    BookIcon,
    MessageCircleIcon,
    mailbox,
  },

  methods: {},
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">Contact Us</h4>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- Start Contact -->
    <section class="section pb-0">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="card contact-detail text-center border-0">
              <div class="card-body p-0">
                <div class="icon">
                  <img
                    src="images/icon/call.svg"
                    class="avatar avatar-small"
                    alt=""
                  />
                </div>
                <div class="content mt-3">
                  <h4 class="title font-weight-bold">Phone</h4>
                  <p class="text-muted">
                    Start working with PromiTech today
                  </p>
                  <a href="tel:12029776666" class="text-primary"
                    >+1 (202) 977-6666</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="card contact-detail text-center border-0">
              <div class="card-body p-0">
                <div class="icon">
                  <img
                    src="images/icon/Email.svg"
                    class="avatar avatar-small"
                    alt=""
                  />
                </div>
                <div class="content mt-3">
                  <h4 class="title font-weight-bold">Email</h4>
                  <p class="text-muted"></p>
                  <a
                    href="mailto:info@epromitechsolutions.com"
                    class="text-primary"
                    >info@promitechsolutions.com</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="card contact-detail text-center border-0">
              <div class="card-body p-0">
                <div class="icon">
                  <img
                    src="images/icon/location.svg"
                    class="avatar avatar-small"
                    alt=""
                  />
                </div>
                <div class="content mt-3">
                  <h4 class="title font-weight-bold">Location</h4>
                  <p class="text-muted">
                    8609 Westwood Center Drive, Suite 110
                    <br />Tysons Corner, VA 22182
                  </p>
                  <a
                    href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    class="video-play-icon h6 text-primary"
                    >View on Google map</a
                  >
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
            <mailbox />
          </div>
          <!--end col-->

          <div class="col-lg-7 col-md-6 order-1 order-md-2">
            <div class="card border-0">
              <div class="card-body p-0">
                <img src="images/contact.svg" class="img-fluid" alt="" />
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>

      <!--end container-->

      <div class="container-fluid mt-100 mt-60">
        <div class="row">
          <div class="col-12 p-0">
            <div class="card map border-0">
              <div class="card-body p-0">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.8047296710456!2d-77.24908018464876!3d38.9284404795659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64a5de76880a3%3A0x82128d5ebfab59d2!2s8609%20Westwood%20Center%20Dr%20%23110%2C%20Vienna%2C%20VA%2022182!5e0!3m2!1sen!2sus!4v1613010774878!5m2!1sen!2sus"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End contact -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->

    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
